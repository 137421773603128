import { template as template_14da8af292344d979c86e0350686f29e } from "@ember/template-compiler";
const FKControlMenuContainer = template_14da8af292344d979c86e0350686f29e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
