import { template as template_9ade5470b6a0469d98d64cf2a8073169 } from "@ember/template-compiler";
const FKLabel = template_9ade5470b6a0469d98d64cf2a8073169(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
