import { template as template_583b78919f7446dca01852bdd281e578 } from "@ember/template-compiler";
const SidebarSectionMessage = template_583b78919f7446dca01852bdd281e578(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
